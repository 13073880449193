@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .v-shape {
    fill: linear-gradient(0deg, #FFF 0%, #FFF 100%), #222B32;
    filter: drop-shadow(0px 58px 100px rgba(69, 72, 136, 0.10));
  }

  .home-hero-gradient-bg {
    position: relative;
    overflow: hidden;
  }

  .home-hero-gradient-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
    z-index: 1;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
    z-index: 1;
  }

  .mobile-gradient-green {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url(./assets/images/SustainablePackagaing-min.png);
  }

  .product-mobile-gradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(./assets/images/ProductPageHero-min.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .home-hero-gradient-bg {
    // background: url(./assets/images/hero-bg.svg), lightgray 50% / cover no-repeat;
  }

  .bg-gradient-default {

    background: linear-gradient(297deg, #E2ECFF 8.25%, #FFF 91.76%), #FAFCFF;
  }

  .services-page-mobile-gradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(./assets/images/service-page-hero-min.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 768px) {
    .product-mobile-gradient {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: linear-gradient(0deg, rgba(13, 13, 43, 0.59) 0%, rgba(13, 13, 43, 0.59) 100%), url(./assets/images/ProductPageHero-min.png);
    }

    .services-page-mobile-gradient {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: linear-gradient(0deg, rgba(13, 13, 43, 0.59) 0%, rgba(13, 13, 43, 0.59) 100%), url(./assets/images/service-page-hero-min.png);
    }

    .bg-gradient-default {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .mobile-gradient-green {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: linear-gradient(0deg, rgba(13, 13, 43, 0.59) 0%, rgba(13, 13, 43, 0.59) 100%), url(./assets/images/green-environment-bg.png);
    }

    .home-hero-gradient-bg {
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;

    }
  }
}